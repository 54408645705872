,
<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Importing station..."
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-overlay :show="isSaving" no-wrap></b-overlay>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group id="fieldset-1" description="" label="" label-for="input-1" class="pt-3">
          <b-form-file
            accept="text/csv"
            v-model="file"
            placeholder="กรุณาเลือกไฟล์"
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
      </form>
    </b-modal>
    <!--end::Body-->
  </div>
</template>

<script>
import * as Services from '../services.js';

export default {
  name: 'import-station-comp',
  props: {
    type: String,
  },
  data() {
    return {
      mode: null,
      file: null,
      isShow: false,
      isSaving: false,
    };
  },
  mounted() {},
  methods: {
    showModal() {
      this.$bvModal.show('modal-prevent-closing');
    },
    save() {
      this.isSaving = true;
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('type', this.type);
      this.$store
        .dispatch(Services.IMPORT_STATION_DATA, formData)
        // go to which page after successfully login
        .then((res) => {
          // this.data = res.data[0];
          this.isSaving = false;
          if (res.status) {
            this.$bvToast.toast('Station data has been imported.', {
              title: 'Successfully',
              variant: 'success',
              solid: true,
            });
            this.$parent.getData();
          } else {
            this.$bvToast.toast(res.message || res.data.error, {
              title: 'Error!',
              variant: 'danger',
              solid: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isSaving = false;
        });
    },

    resetModal() {},
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      //Save Data
      this.save();
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
      });
    },
  },
};
</script>

<style></style>
